<template>
  <div class="push">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>智能推送</el-breadcrumb-item>
    </el-breadcrumb>
    <div >
      <div class="push-banner">
        <!-- <div>
          <span @click="openPushSet"
            ><img src="../../../assets/pc/images/push/set.png" />推送设置</span
          >
        </div> -->
        <div class="push_code">
          <div>
            <img src="../../../assets/pc/images/push/greenWeChat.png" />
          </div>
          <div>
            <span>微信推送</span>
            <div>请扫码关注公众号，关注后才能接收推送消息</div>
          </div>
          <div>
            <img
              v-loading="imgLoading"
              element-loading-background="#FFF"
              :src="wechatCode"
              title="点击刷新二维码"
              style="width: 100px; height: 100px; cursor: pointer;"
              @click="getQRCode"
            />
          </div>
        </div>
        <div>
          <h3 style="margin:20px 0">智能推送</h3>
          <div>自动匹配适合申报的项目，并推送到手机</div>
        </div>
      </div>
      <div class="push-choose">
        <pushChoose v-model="pushChoose" @change="handleChoose" />
        <div class="perfect" v-if="$route.path === '/push/adaptationproject'">
          <div>
            提醒告知
            <el-switch v-model="value" @change="changeSwitch"></el-switch>
          </div>
          及时完善资料，推送更精准<el-button
            plain
            size="mini"
            @click="goToPersonal"
            >去完善</el-button
          >
        </div>
      </div>

      <!-- <el-dialog
        class="push-dialog"
        title="推送设置"
        :visible.sync="pushSet"
        width="480px"
      >
        <div>
          提醒告知
          <el-switch v-model="value" @change="changeSwitch"></el-switch>
        </div>
        <div>有最新发布的政策项目，自动进行短信/微信推送</div>
        <div></div>
        <div>
          <div>
            <img src="../../../assets/pc/images/push/WeChat.png" />
          </div>
          <div>
            <div>微信推送</div>
            <div>关注公众号，随时获取最新政策资讯</div>
          </div>
          <div>
            <img
              v-loading="imgLoading"
              element-loading-background="#FFF"
              :src="wechatCode"
              title="点击刷新二维码"
              style="width: 100px; height: 100px; cursor: pointer;"
              @click="getQRCode"
            />
          </div>
        </div>
      </el-dialog> -->

      <router-view />
    </div>
  </div>
</template>

<script>
import pushChoose from "./components/pushChoose";
import { request } from "../../../network";
import {
  setInfoData,
  getMemberId,
  // getEntityId
} from "@/assets/public/utils/token";
export default {
  components: {
    pushChoose,
  },
  data() {
    return {
      pushChoose: "/push/adaptationproject",
      pushSet: false,
      value: sessionStorage.getItem('pushSwitch') === 'true',
      wechatCode: '',
      imgLoading: false
    };
  },
  mounted () {
    this.handleRoute();
    this.getQRCode();
    if (!sessionStorage.getItem('pushSwitch')){
      this.getPushSet();
    }
  },
  computed: {},
  methods: {
    async getPushSet() {
      const { data: res } = await request({
        method: "get",
        url: "/pcp/intelligentPush/pushSet",
        params: {
          id: getMemberId(),
        },
      });
      if (res.code !== 200) return this.$message.error("获取推送设置失败");
      this.value = res.data === 1;
      sessionStorage.setItem('pushSwitch', this.value);
    },
    async setPushSet() {
      setInfoData(request).then(async (res) => {
        if (res === "islogin") {
          if (getMemberId() !== "") {
            const { data: res } = await request({
              method: "get",
              url: "/pcp/intelligentPush/pushSet",
              params: {
                type: this.value === true ? 1 : 0,
                id: getMemberId(),
              },
            });
            if (res.code !== 200) return this.$message.error("设置失败");
            this.$message.success("设置成功");
            sessionStorage.setItem('pushSwitch', this.value);
          }
        } else {
          this.$message.error("请先登录再进行操作");
        }
      });
    },
    changeSwitch() {
      this.setPushSet();
    },
    handleRoute() {
      this.pushChoose = this.$route.path;
    },
    handleChoose(data) {
      this.$router.push({ path: data });
    },
    goToPersonal() {
      setInfoData(request).then((res) => {
        if (res === "islogin") {
          this.$router.push({
            path: "/personal/perfect",
          });
        } else {
          this.$message.error("请先登录再进行操作");
        }
      });
    },
    async getQRCode() {
      this.pushSet = true;
      this.imgLoading = true;
      const { data: res } = await request({
        method: 'get',
        url: '/pcp/officialAccounts/getQRCode/' + getMemberId()
      });
      if (res.code !== 200) return this.$message.error('获取微信公众号二维码失败');
      this.wechatCode = res.data;
      this.imgLoading = false;
    },
    // openPushSet() {
    //   this.pushSet = true;
    //   this.imgLoading = true;
    //   this.getQRCode().finally(_ => {
    //     this.imgLoading = false;
    //   });
    // }
  },
  watch: {
    $route: "handleRoute",
  },
};
</script>

<style lang="less" scoped>
.push {
  padding: 43px 0px;
  background-color: #fff;
  .push-banner {
    margin-top: 30px;
    width: 1200px;
    height: 160px;
    background: url("~@/assets/pc/images/push/pushBG.png") no-repeat;
    background-size: 100% 100%;
    padding: 24px 23px 47px 45px;
    box-sizing: border-box;
    .push_code{
      float: right;
      display: flex;
      align-items: center;
      img{
        margin: 0 20px;
      }
      &>div:nth-child(2){
        text-align: left;
        font-size: 16px;
        line-height: 26px;
        span{
          font-weight: bold;
        }
        div{
          color: #888888;
        }
      }
    }
    & > div:nth-child(1) {
      text-align: right;
      font-size: 14px;
      span {
        cursor: pointer;
        img {
          margin-right: 5px;
        }
      }
    }
    h3 {
      width: 130px;
      font-size: 30px;
      font-weight: 400;
    }
  }
  .push-choose {
    margin-top: 20px;
    margin-bottom: 30px;
    display: flex;
    .perfect {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 1;
      line-height: 63px;
      font-size: 12px;
      &>div{
        margin-right: 20px;
      }
      .el-button {
        margin-left: 10px;
      }
    }
  }
  .push-dialog {
    /deep/.el-dialog__header {
      border-bottom: 1px solid #e5e5e5;
      .el-dialog__title {
        font-size: 16px;
        font-weight: bold;
      }
    }
    /deep/.el-dialog__body {
      padding: 30px;
      font-size: 16px;
      color: #000;
      & > div:nth-child(2) {
        margin-top: 20px;
        color: #8d8d8d;
      }
      & > div:nth-child(3) {
        display: flex;
        margin-top: 34px;
        width: 420px;
        height: 120px;
        background-color: #f7f7f7;
        padding: 20px;
        box-sizing: border-box;
        & > div {
          align-self: center;
        }
        & > div:nth-child(2) {
          flex: 1;
          margin-left: 10px;
          font-weight: bold;
          div:nth-child(2) {
            margin-top: 14px;
            font-size: 14px;
            color: #888888;
            font-weight: normal;
          }
        }
      }
    }
  }
}
/deep/.el-switch.is-checked .el-switch__core {
  background-color: #156ED0;
}
</style>